/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { logoutRequestAction } from '../../store/account/userActions';
import {
  walletAccountDetailModalAction,
  WalletConnectModalAction,
} from '../../store/wallet/walletActions';
import { createBrowserHistory } from 'history';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import './MercurXNavbar.scss';
import multisignalsLogo from '../../assets/img/logo/logo.png';
import { mainColors } from '../../helpers/colors';
import { Popover } from 'react-tiny-popover';
import { Col, Row } from 'react-bootstrap';
import { getTierRequestAction } from '../../store/tier/tierActions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

function Navigation({ ...props }) {
  const {
    user,
    accounts,
    balance_,
    walletAccountDetailModalRequest,
    logoutRequest,
    WalletConnectModalRequest,
    token,
    project,
    history,
    getTierRequest,
    tierData,
    MainLayoutStatus,
    isPublic,
  } = props;
  const pathname = history?.location.pathname;
  const pathnameHash = history?.location.hash;

  const [profileOpen, setProfileOpen] = useState(false);
  const [aboutOpen, setAboutOpen] = useState(false);
  const { utils } = require('ethers');
  const { pathname: activePath } = useLocation();
  const handleLogout = () => {
    console.log("Logout clicked");
    logoutRequest();
  };

  const pathIsActive = (path) => {
    const history = createBrowserHistory();
    const pathname = history.location.pathname.split('/')[1];

    if (pathname?.toLowerCase() === path.toLowerCase()) {
      return true;
    }
    return false;
  };
  const handleShow = () => {
    walletAccountDetailModalRequest(true);
  };
  const handleShowWallet = () => {
    WalletConnectModalRequest(true);
  };

  function getProfilePopoverContent() {
    return (
      <div className="bg-white rounded shadow p-1">
        <Link className="navbar-popover-link p-1 rounded d-block" to="/profile">
          Profile
        </Link>
        <div className="navbar-popover-link p-1 rounded d-block" onClick={handleLogout}>
          Logout
        </div>
      </div>
    );
  }
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  function getAboutPopoverContent() {
    return (
      <div className="about-popover-container bg-white rounded shadow p-2">
        <Row>
          {user && (
            <Col sm="12" md="6">
              <Link
                className="navbar-popover-link p-1 rounded d-block"
                to="/about"
                onClick={() => setAboutOpen(false)}
              >
                <>
                  {isMobile ? <></> : <div className="text-fs-head-xs">About MultiSignals</div>}
                  {isMobile ? (
                    <div className="text-fs-body-sm text-t-body-color">
                      About MultiSignals
                    </div>
                  ) : (
                    <div className="text-fs-body-sm text-t-body-color">
                      Instructions and tips about MultiSignals
                    </div>
                  )}
                </>
              </Link>
            </Col>
          )}
          <Col sm="12" md={user ? "6" : "12"}>
            <div
              className="navbar-popover-link p-1 rounded d-block"
              onClick={() => {
                window.open('https://docs.multisignals.com', '_blank');
                setAboutOpen(false);
              }}
            >
              {isMobile ? <></> : <div className="text-fs-head-xs">User Guide</div>}
              {isMobile ? (
                <div className="text-fs-body-sm text-t-body-color">
                  User Guide
                </div>
              ) : (
                <div className="text-fs-body-sm text-t-body-color">
                  Instructions and tips about MultiSignals
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
  

  function controlPath(cPath) {
    return activePath.startsWith(cPath);
  }
  return (
    <div>
      <Navbar className="mercurx-navbar" expand="lg" style={{ zIndex: 1 }}>
        <Container>
          {!user && isPublic && (
            <Navbar.Brand as={Link} to="/">
              <img src={multisignalsLogo} alt="Logo" width={100} />
            </Navbar.Brand>
          )}
          {user && (
            <Navbar.Brand as={Link} to="/">
              <img src={multisignalsLogo} alt="Logo" width={100} />
            </Navbar.Brand>
          )}
          <Navbar.Toggle aria-controls="basic-navbar-nav" className="bg-white" />
          <Navbar.Collapse
            id="basic-navbar-nav"
            className="justify-content-end flex-column align-items-end navbar-design"
            data-ispublic={isPublic}
          >
            <Nav className="ml-auto align-items-center py-3 pe-2" variant="pills" data-ispublic={isPublic}>
              {user && (
                <>
                  <Nav.Link
                    as={Link}
                    className={`${activePath === '/home' ? 'active-menu-item' : ''}`}
                    to="/home"
                  >
                    <MercurXIcons name="BsHouse" size={20} />
                    Home
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    className={`${activePath === '/marketplace' ? 'active-menu-item' : ''}`}
                    to="/marketplace"
                  >
                    <MercurXIcons name="BiHomeSmile" size={20} />
                    Marketplace
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    className={`${activePath === '/my-bot-history' ? 'active-menu-item' : ''}`}
                    to="/my-bot-history"
                  >
                    <MercurXIcons name="BiBarChart" size={20} />
                    My Bot History
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    className={`${controlPath('/general-settings/api-settings')
                      ? 'active-menu-item' : ''}`}
                    to="/general-settings/api-settings"
                  >
                    <MercurXIcons name="BsKey" size={20} />
                    API Settings
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    className={`${controlPath('/general-settings/bot-management') ? 'active-menu-item' : ''}`}
                    to="/general-settings/bot-management"
                  >
                    <MercurXIcons name="FaRobot" size={20} />
                    Bot Management
                  </Nav.Link>
                </>
              )}

              <Popover
                isOpen={aboutOpen}
                padding={10}
                positions={['bottom']}
                content={getAboutPopoverContent()}
                onClickOutside={() => setAboutOpen(false)}
              >
                <Nav.Link
                  className={
                    'text-fs-head-xs text-muted px-2 public-nav' +
                    (pathIsActive('about') || pathIsActive('whitepaper') ? ' active' : '')
                  }
                  onClick={() => setAboutOpen(!aboutOpen)}
                >
                  <span className="d-flex align-items-center">
                    <div className=''>About</div>
                    <MercurXIcons name={aboutOpen ? 'BiChevronUp' : 'BiChevronDown'} size={24} />
                  </span>
                </Nav.Link>
              </Popover>
              {!user && (
                <>
                  <Nav.Link
                    as={Link}
                    data-ispublic={isPublic}
                    className={
                      'text-fs-head-xs px-2 public-nav' + (pathIsActive('login') ? ' active' : '')
                    }
                    to="/login"
                  >
                    Login
                  </Nav.Link>
                  <Nav.Link
                    as={Link}
                    data-ispublic={isPublic}
                    className={
                      'text-fs-head-xs px-2 public-nav' + (pathIsActive('signup') ? ' active' : '')
                    }
                    to="/signup"
                  >
                    Sign up
                  </Nav.Link>
                </>
              )}
              {user && (
                <Popover
                  isOpen={profileOpen}
                  padding={10}
                  positions={['bottom']}
                  content={getProfilePopoverContent()}
                  onClickOutside={() => setProfileOpen(false)}
                >
                  <div
                    className="bg-white py-1 px-2 m-1 shadow rounded cursor-pointer d-flex align-items-center"
                    onClick={() => setProfileOpen(!profileOpen)}
                  >
                    <MercurXIcons name="MdPerson" color={mainColors['dark-light']} size="26" />
                    <span className="ms-2">
                      {user?.full_name?.length > 10
                        ? `${user.full_name.slice(0, 10)}...`
                        : user.full_name}
                    </span>
                  </div>
                </Popover>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}
const mapStateToProps = (state) => {
  return {
    user: state.userReducer.user,
    accounts: state.walletReducer.accounts,
    token: state.tokenReducer.token,
    project: state.projectReducer.project,
    balance_: state.walletReducer.balance_,
    tierData: state.tierReducer.tierData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTierRequest: (payload) => {
      dispatch(getTierRequestAction(payload));
    },
    logoutRequest: (payload) => {
      dispatch(logoutRequestAction(payload));
    },
    walletAccountDetailModalRequest: (payload) => {
      dispatch(walletAccountDetailModalAction(payload));
    },
    WalletConnectModalRequest: (payload) => {
      dispatch(WalletConnectModalAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Navigation);
