/* eslint-disable max-len */
import React, { useEffect } from 'react';
import './Marketplace.scss';
import { Button, Container, Col, Card, Row, Popover, OverlayTrigger } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  getBotRequestAction,
  getRegisteredBotRequestAction,
  registerRequest
} from '../../store/bot/botActions';
import { LineChart, Line, CartesianGrid } from 'recharts';
import { setLoadingAction, setLoadingFullAction } from '../../store/loading/loadingActions';
import { useHistory } from 'react-router-dom';
import { accountDetailsRequestAction } from '../../store/account/userActions';
import bnbusdt from '../../assets/img/coin/bnbusdt.png';
import okxusdt from '../../assets/img/coin/okxusdt.png';
import high from '../../assets/img/risk/high.png';
import medium from '../../assets/img/risk/medium.png';
import low from '../../assets/img/risk/low.png';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import { mainColors } from '../../helpers/colors';

const data = [
  { name: 'a', uv: 12, pv: -10 },
  { name: 'b', uv: 19, pv: 0 },
  { name: 'c', uv: 30, pv: 5 },
  { name: 'c', uv: 30, pv: 3 },
  { name: 'c', uv: 30, pv: 8 },
  { name: 'c', uv: 30, pv: 20 },
  { name: 'c', uv: 30, pv: 1 },
];

function Marketplace({ botData, registeredBotData, getBotRequest, getRegisteredBotRequest, user, accountDetailsRequest }) {
  const history = useHistory();

  useEffect(() => {
    getBotRequest();
    accountDetailsRequest();
    getRegisteredBotRequest();
  }, [getBotRequest, accountDetailsRequest, getRegisteredBotRequest]);

  const botDataArray = botData ? Object.values(botData) : [];

  const routeStrategie = (e, id) => {
    e.stopPropagation();
    history.push(`/general-settings/bot-management/${id}`);
  };

  const redirectApiKey = () => {
    history.push('/general-settings/api-settings');
  };

  const riskPopover = (risk) => (
    <Popover id={`popover-${risk}`}>
      <Popover.Body>
        {risk === 'low' && 'This bot is designed for low-risk investments, prioritizing stability and safety.'}
        {risk === 'medium' && 'This bot balances risk and reward, executing a moderate number of trades.'}
        {risk === 'high' && 'This bot focuses on aggressive strategies, leveraging market volatility for maximum returns.'}
      </Popover.Body>
    </Popover>
  );

  return (
    <Container>
      <div className="text-fs-head-sm text-start">Strategies</div>
      <div className="border-0 mt-4 position-relative">
        <Row className="mb-5">
          {botDataArray.map((item, index) => {
            const isBotRegistered = registeredBotData?.bots?.some(bot => bot.bot_id === item.id && bot.registration_status === true);
            const hasApiKey = user?.binance || user?.okx;
            const exchangeName = user?.binance ? 'Binance' : user?.okx ? 'OKX' : '';

            return (
              <Col key={index} sm={12} md={4}>
                <Card className="marketplace-card py-2 px-3 shadow">
                  <div className="text-fs-head-xs mx-auto">{item.name}</div>
                  <div className="d-flex justify-content-center align-items-center">
                    {item.status ? 'Active' : 'Inactive'}
                  </div>
                  <hr />
                  <LineChart width={300} height={200} data={data}>
                    <CartesianGrid stroke="#0000" strokeDasharray="5 5" />
                    <Line type="monotone" dataKey="pv" stroke="#097875" />
                  </LineChart>
                  <hr />
                  <div className="marketplace-card-content">
                    <div className="text-fs-body-md">• Creator: {item.creator}</div>
                    <div className="text-fs-body-md">• Created Date: {new Date(item.cdate).toLocaleString()}</div>
                    <div className="text-fs-body-md d-flex align-items-center">
                      • Risk:
                      <span className={`risk-value-${item.risk}`} style={{ marginLeft: 8 }}>
                        {item.risk}
                      </span>
                      <img src={item.risk === 'low' ? low : item.risk === 'medium' ? medium : high} alt="risk" width={24} height={24} style={{ marginLeft: 8 }} />
                      <OverlayTrigger placement="top" overlay={riskPopover(item.risk)}>
                        <span style={{ marginLeft: 8, cursor: 'pointer' }}>
                          <MercurXIcons name="FaInfoCircle" color={mainColors.primary} />
                        </span>
                      </OverlayTrigger>
                    </div>
                    <div className="text-fs-body-md">
                      • {item.exchanges.length > 1 ? 'Exchanges' : 'Exchange'}:
                      <span> </span>
                      {item.exchanges.map((exchange, idx) => (
                        <span key={idx}>
                          <span>
                            {exchange.name.charAt(0).toUpperCase() + exchange.name.slice(1).toLowerCase()}
                          </span>
                          <img
                            src={exchange.name.toLowerCase() === 'binance' ? bnbusdt : okxusdt}
                            width={24}
                            height={24}
                            style={{ marginLeft: 2, marginBottom: 3 }}
                          />
                          {idx !== item.exchanges.length - 1 && <span>&nbsp;</span>}
                        </span>
                      ))}
                    </div>


                  </div>
                  <hr />
                  <Row>
                    <Button
                      onClick={(e) => {
                        if (!hasApiKey) {
                          redirectApiKey();
                        } else {
                          routeStrategie(e, item.id);
                        }
                      }}
                      className="btn btn-primary"
                    >
                      <span className="ms-1">
                        {!hasApiKey ? 'Add API Key' : isBotRegistered ? `Registered - ${exchangeName}` : 'Change Exchange'}
                      </span>
                    </Button>
                  </Row>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    </Container>
  );
}

const mapStateToProps = (state) => {
  return {
    botData: state.botReducer.botData,
    registeredBotData: state.botReducer.registeredBotData,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBotRequest: (payload) => {
      dispatch(getBotRequestAction(payload));
    },
    getRegisteredBotRequest: (payload) => {
      dispatch(getRegisteredBotRequestAction(payload));
    },
    setLoading: (payload) => {
      dispatch(setLoadingAction(payload));
    },
    setLoadingFull: (payload) => {
      dispatch(setLoadingFullAction(payload));
    },
    registerRequest: (creds) => {
      dispatch(registerRequest(creds));
    },
    accountDetailsRequest: (payload) => {
      dispatch(accountDetailsRequestAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Marketplace);
