import * as types from './userActionTypes';

const initialState = {
  signUpData: null,
  forgotPasswordData: null,
  resetPasswordData: null,
  walletAccount: null,
  accountVerifiedData: null,
  resendVerificationEmailData: null,
  user: null,
  checkUserTokenData: null,
  enable2faData: [],
  generateQrData: null,
  verify2faData: null,
  create2faData: null,
  two_factor_auth: false,
  kyc: null,
  getKyc:null,
  error: {
    type: null,
    data: null,
  },
};

export const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_DATA:
      return {
        ...state,
        user: action.user ? Object.assign({}, action.user) : null,
      };
    case types.LOGIN_ERROR:
      return {
        ...state,
        error: {
          type: types.LOGIN_ERROR,
          data: action.payload.result,
        },
      };
    case types.SIGN_UP_DATA:
      return {
        ...state,
        signUpData: action.payload ? Object.assign({}, action.payload.result) : null,
      };
    case types.SIGN_UP_ERROR:
      return {
        ...state,
        error: {
          type: types.SIGN_UP_ERROR,
          data: action.payload.result,
        },
      };
    case types.FORGOT_PASSWORD_DATA:
      return {
        ...state,
        forgotPasswordData: action.payload ? Object.assign({}, action.payload.result) : null,
      };
    case types.FORGOT_PASSWORD_ERROR:
      return {
        ...state,
        error: {
          type: types.FORGOT_PASSWORD_ERROR,
          data: action.payload.result,
        },
      };
    case types.RESET_PASSWORD_DATA:
      return {
        ...state,
        resetPasswordData: action.payload ? Object.assign({}, action.payload.result) : null,
      };
    case types.RESET_PASSWORD_ERROR:
      return {
        ...state,
        error: {
          type: types.RESET_PASSWORD_ERROR,
          data: action.payload.result,
        },
      };
    case types.ACTIVATION_DATA:
      return {
        ...state,
        activationData: action.payload ? Object.assign({}, action.payload.result) : null,
      };
    case types.ACTIVATION_ERROR:
      return {
        ...state,
        error: {
          type: types.ACTIVATION_ERROR,
          data: action.payload.result,
        },
      };
    case types.LOGOUT_DATA:
      return {
        ...state,
        user: null,
      };
    case types.LOGOUT_ERROR:
      return {
        ...state,
        error: {
          type: types.LOGOUT_ERROR,
          data: action.payload.result,
        },
      };
    case types.ACCOUNT_DETAILS_DATA:
      const payload = {
        user: action.payload.result,
        token: JSON.parse(localStorage.getItem('user')).token,
      };
      console.log('payload')
      console.log(payload)
      localStorage.setItem('user', JSON.stringify(payload));
      return {
        ...state,
        user: action.payload ? Object.assign({}, action.payload.result) : null,
      };
    case types.ACCOUNT_DETAILS_ERROR:
      return {
        ...state,
        error: {
          type: types.ACCOUNT_DETAILS_ERROR,
          data: action.payload.result,
        },
      };
    case types.ACCOUNT_VERIFIED:
      return {
        ...state,
        accountVerifiedData: action?.payload.result,
      };
    case types.RESEND_VERIFICATION_EMAIL_DATA:
      return {
        ...state,
        resendVerificationEmailData: action.payload.result ?
         Object.assign({}, action.payload.result) : null,
      };
    case types.RESEND_VERIFICATION_EMAIL_ERROR:
      return {
        ...state,
        error: {
          type: types.RESEND_VERIFICATION_EMAIL_ERROR,
          data: action.payload.result,
        },
      };
    case types.CHECK_USER_TOKEN_DATA:
      return {
        ...state,
        checkUserTokenData: action.payload.result,
      };
    case types.CHECK_USER_TOKEN_ERROR:
      return {
        ...state,
        error: {
          type: types.CHECK_USER_TOKEN_ERROR,
          data: action.payload.result,
        },
      };
    case types.ENABLE_2FA_DATA:
      console.log('ENABLE2FADATA')
      console.log(action.payload)
      return {
        ...state,
        enable2faData: action.payload.result,
      };

    case types.GENERATE_QR_DATA:
      const generateQrDataResult = action.payload ? Object.assign({}, action.payload.result): null;
      console.log('generateQrDataResult')
      console.log(generateQrDataResult)
      return {
        ...state,
        generateQrData: generateQrDataResult ,
      };
    case types.CREATE_2FA_DATA:
      const create2faDataResult = action.payload ? Object.assign({}, action.payload.result): null;
      console.log('create2faDataResult')
      console.log(create2faDataResult)
      return {
        ...state,
        create2faData: create2faDataResult,
      };
    case types.VERIFY_2FA_DATA:
      const verify2faDataResult = action.payload ? Object.assign({}, action.payload.result): null;
      console.log(verify2faDataResult)
      return {
        ...state,
        verify2faData: verify2faDataResult,
      };
    case types.ENABLE_2FA_ERROR:
      return {
        ...state,
        error: {
          type: types.ENABLE_2FA_ERROR,
          data: action.payload.result,
        },
      };

    case types.GENERATE_QR_ERROR:
      return {
        ...state,
        error: {
          type: types.GENERATE_QR_ERROR,
          data: action.payload.result,
        },
      };
    case types.CREATE_2FA_ERROR:
      return {
        ...state,
        error: {
          type: types.VERIFY_2FA_ERROR,
          data: action.payload.result,
        },
      };
    case types.VERIFY_2FA_ERROR:
      return {
        ...state,
        error: {
          type: types.VERIFY_2FA_ERROR,
          data: action.payload.result,
        },
      };
    case 'VERIFY_2FA_SUCCESS':
      return {
        ...state,
        verify2faData: action.payload.result,
        verify2faError: null,
      };
    default:
      return state;
  }
};
