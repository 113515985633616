/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Col, Container, Form, Row, Tab, Tabs, Modal } from 'react-bootstrap';
import {
  accountDetailsRequestAction,
  resendVerificationEmailRequestAction,
  enable2faRequestAction,
  generateQrRequestAction,
  create2faRequestAction,
  logoutRequestAction
} from '../../store/account/userActions';
import { checkUserVerified } from '../../helpers/verificationHelper';
import './Profile.scss';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import { mainColors } from '../../helpers/colors';
import { tierInfo } from '../../helpers/tierInfo';
import { useHistory } from 'react-router-dom';
import { setAlertAction } from '../../store/alert/alertActions';

function Profile({ ...props }) {
  const {
    user,
    accountDetailsRequest,
    accounts,
    resendVerificationEmailRequest,
    tierData,
    enable2faRequest,
    generateQrRequest,
    create2faRequest,
    create2faData,
    enable2faData,
    enable2faError,
    generateQrData,
    logoutRequest,
    setAlert,
    twoFactorAuth,
  } = props;
  const [activeTab, setActiveTab] = useState(props.location.pathname.substring(1));
  const [selectedTier, setSelectedTier] = useState(tierInfo?.[tierData?.tier]);
  const [switchValue, setSwitchValue] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const history = useHistory();
  const [step, setStep] = useState(1);
  const [inputValue, setInputValue] = useState('');
  const [isFinished, setIsFinished] = useState(false);
  useEffect(() => {
    accountDetailsRequest();
  }, []);
  useEffect(() => {
    if (tierData?.tier !== null && tierData?.tier !== undefined) {
      setSelectedTier(tierInfo?.[tierData?.tier]);

    }
  }, [tierData]);
  useEffect(() => {
    if (
      props.location.hash.substring(1) === 'verify' ||
      props.location.hash.substring(1) === 'kyc' ||
      props.location.hash.substring(1) === '2fa' ||
      props.location.hash.substring(1) === 'profile' ||
      props.location.hash.substring(1) === 'connections'
    ) {
      setActiveTab(props.location.hash.substring(1));
    }
  }, [props.location]);

  useEffect(() => {



    if (enable2faData && isFinished) {
      setShowModal(false);
      setTimeout(() => {
        logoutRequest();
      }, 1000);
      setInputValue('');
    } else {
    }
    setSwitchValue(user.two_factor_auth)
  }, [props.user, props.enable2faData, props.generateQrData, props.create2faData]);


  function verifyEmail() {
    resendVerificationEmailRequest();
  }

  const handleSelect = (e) => {
    //set state
    setActiveTab(e);

    props.history.replace({
      hash: `${e}`,
    });
  };
  const handleSwitchChange = async () => {
    try {
      if (switchValue) {
        setShowModal(true);
        setStep(2);
      } else {
        const payload = {};
        await create2faRequest(payload);
        await generateQrRequest(payload);
        setShowModal(true);
        setStep(1);
      }
    } catch (error) {
    }
  };

  const handleCloseModal = () => {
    // Close the modal
    setShowModal(false);
    setStep(1);
  };
  const handleNextStep = () => {
    setStep((prevStep) => prevStep + 1);
  };
  const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text)
      .then(() => {
        setAlert({
          title: 'Success!',
          text: 'Text copied to clipboard',
          variant: 'success',
          outTimeMS: 2000,
        });
      })
      .catch((err) => {
      });
  };
  const handleInputChange = (e) => {
    let value = e.target.value;
    value = value.replace(/-/g, "");
    value = value.replace(/[^0-9]/g, "").slice(0, 6);
    if (value.length > 3) {
      value = value.slice(0, 3) + "-" + value.slice(3);
    }
    setInputValue(value);
  };
  const handleFinish = async () => {
    try {
      const totp_code = inputValue.replace(/-/g, "");
      const payload = {
        totp_code: totp_code,
        history
      };
      await enable2faRequest(payload);
      setIsFinished(true)

    } catch (error) {
    }
  };

  return (
    <>
      <Container className="justify-content-center align-items-center col-lg-10 col-sm-6">
        <Tabs
          defaultActiveKey="profile"
          activeKey={activeTab ? activeTab : 'profile'}
          className="text-fs-head-xs"
          justify
          onSelect={(e) => handleSelect(e)}
        >
          <Tab
            eventKey="profile"
            title={
              <div className="d-flex justify-content-center align-items-center">
                <MercurXIcons name="MdPerson" />
                <span className="ms-1">Profile</span>
              </div>
            }
          >
            {activeTab === 'profile' ? (
              <Col className="mt-5 mb-5">
                <Row className="d-flex justify-content-center align-items-center">
                  <b className="d-flex justify-content-center text-fs-head-xs">Name/Surname</b>{' '}
                  <Form.Group className="col-8 mt-3">
                    {' '}
                    <Form.Control
                      value={user.full_name}
                      type="text"
                      className="text-fs-body-md text-t-body-color bg-light"
                      disabled
                    />
                  </Form.Group>
                </Row>
                <Row className="d-flex justify-content-center align-items-center">
                  <b className="d-flex justify-content-center text-fs-head-xs mt-2">Email</b>{' '}
                  <Form.Group className="col-8 mt-3">
                    {' '}
                    <Form.Control
                      value={user.email}
                      type="text"
                      className="text-fs-body-md text-t-body-color bg-light"
                      disabled
                    />
                  </Form.Group>
                </Row>
                <Row className="d-flex justify-content-center align-items-center mt-3 md-2">
                  {checkUserVerified(user) ? (
                    <Button disabled={true} variant="primary" className="col-4">
                      Email Verified
                    </Button>
                  ) : (
                    <Button variant="primary" onClick={verifyEmail} className="col-4">
                      Verify Email
                    </Button>
                  )}
                </Row>
              </Col>
            ) : (
              <div></div>
            )}
          </Tab>
          {/* <Tab
            eventKey="kyc"
            title={
              <div className="d-flex justify-content-center align-items-center">
                <MercurXIcons name="BiLockAlt" />
                <span className="ms-1">KYC Status</span>
              </div>
            }
          >
            {activeTab === 'kyc' ? (
              <Col className="mt-5 mb-5">
                <Row className="d-flex justify-content-center align-items-center text-fs-head-xs">
                  INDIVIDUAL KYC VERIFICATION
                </Row>
                <Row className="d-flex justify-content-center align-items-center text-center text-fs-body-md mt-3">
                  Leverage on any tokens with a protocol trusted with billions for its performance
                  and reliability.
                </Row>
                <Row className="d-flex justify-content-center align-items-center mt-4">
                  {kycStatus === "false" || kycStatus === "Cancelled" ? (
                    <Button variant="primary" className="col-4" onClick={handleKycStart}>
                      Start
                    </Button>
                  ) : kycStatus === "Approved" ? (
                    <Button variant="primary" className="col-4" disabled={true}>
                      Approved
                    </Button>
                  ) : (
                    <Button variant="primary" className="col-4" disabled={true}>
                      In Progress
                    </Button>
                  )}
                </Row>
              </Col>
            ) : (
              <div></div>
            )}
          </Tab> */}
          <Tab
            eventKey="2fa"
            title={
              <div className="d-flex justify-content-center align-items-center">
                <span className="ms-1">2FA</span>
              </div>
            }
          >
            {activeTab === '2fa' ? (
              <Col className="mt-5 mb-5 text-center">
                <Row className="d-flex justify-content-center align-items-center text-fs-head-xs">
                  TWO FACTOR AUTHENTICATION
                </Row>
                <Row className="d-flex justify-content-center align-items-center mt-4">
                  <Form>
                    <Form.Check
                      className="d-flex justify-content-center"
                      type="switch"
                      id="custom-switch"
                      label={switchValue ? 'Enable' : 'Disable'}
                      onChange={handleSwitchChange}
                      style={{ fontSize: '20px' }}
                      checked={switchValue}
                    />
                  </Form>
                </Row>
              </Col>
            ) : (
              <div></div>
            )}
          </Tab>
          {/* <Tab
            eventKey="verify"
            title={
              <div className="d-flex justify-content-center align-items-center">
                <MercurXIcons name="BiWallet" />
                <span className="ms-1">Verify Wallet</span>
              </div>
            }
          >
            {activeTab === 'verify' ? (
              <Col className="d-flex justify-content-center align-items-center mt-5 mb-5">
                <Row>
                  {checkUserWalletAccount(accounts) ? (
                    <Col className="justify-content-center align-items-center">
                      <Row className="d-flex justify-content-center align-items-center ">
                        <div className="d-flex justify-content-center align-items-center">
                          <MercurXIcons
                            name="BiCheckShield"
                            color={mainColors['primary']}
                            size={50}
                          />
                        </div>
                      </Row>

                      <Row className="d-flex justify-content-center align-items-center mt-3">
                        <div className="col-8 text-fs-head-xs text-center">Wallet is Verified!</div>
                      </Row>
                      <Row className="d-flex justify-content-center align-items-center mt-3">
                        <div className="d-flex text-fs-body-md text-center">
                          {' '}
                          Your verified wallet adress is &nbsp;
                          <div className="text-fs-body-md text-primary">
                            {accounts?.[0].slice(0, 6)}...{accounts?.[0].slice(36, 42)}
                          </div>
                        </div>
                      </Row>
                    </Col>
                  ) : (
                    <Col className="mt-2">
                      <Row className="d-flex justify-content-center align-items-center text-fs-head-xs">
                        Verify Wallet
                      </Row>
                      <Row className="d-flex justify-content-center align-items-center text-fs-body-md text-center mt-2">
                        Leverage on any tokens with a protocol trusted with billions for its
                        performance and reliability.
                      </Row>
                      <Row className="d-flex justify-content-center align-items-center mt-3">
                        <Button
                          className="me-2 col-6"
                          variant="primary"
                          onClick={() => wallet.connectWallet()}
                        >
                          Connect
                        </Button>
                      </Row>
                    </Col>
                  )}
                </Row>
              </Col>
            ) : (
              <div></div>
            )}
          </Tab>
          <Tab
            eventKey="tier"
            title={
              <div className="d-flex justify-content-center align-items-center">
                <MercurXIcons name="BiBarChart" />
                <span className="ms-1">Tier</span>
              </div>
            }
          >
            {activeTab === 'tier' ? (
              <Row className="mt-5 ">
                <div className="d-flex justify-content-center align-items-center">
                  <span className="ms-1 text-fs-head-xs pb-5">Your Tier is - {tierData?.tier}</span>
                </div>
                {tierInfo.map((item, index) => {
                  return (
                    <Col key={index} sm={12} md={4} className="tier-card ">
                      <Card
                        className={`${isActiveTier(item.id) ? 'border-primary border border-3 ' : 'border-0'
                          } py-2 px-3 shadow tier-card`}
                      >
                        <img
                          className="img-fluid mx-auto mb-2"
                          alt="mercurx-mini-icon"
                          src={mercurxMiniIcon}
                          width={50}
                        />
                        <div className="text-fs-head-xs mx-auto">{item.title}</div>
                        <div>REQUIREMENT</div>
                        <div>{item.requirement}</div>
                        <hr></hr>
                        <div style={{ height: '150px' }}>
                          <div className='text-primary'>Key Features</div>
                          {item.features.map((feature, fIndex) => {
                            return (
                              <div
                                key={fIndex}
                                className={`${feature.highlight} text-fs-body-md`}
                              >
                                • {feature.content}
                              </div>
                            );
                          })}</div>
                      </Card>
                    </Col>
                  );
                })}
              </Row>
            ) : (
              <div></div>
            )}
          </Tab> */}
        </Tabs>
      </Container>
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {step === 1 ? 'Secret Key And QR' : step === 2 ? 'Verify Code' : ''}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {step === 1 && create2faData && generateQrData && !switchValue && (
            <div className='text-center'>
              <p>
                <span>{Object.values(create2faData)}</span>
                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => handleCopyToClipboard(Object.values(create2faData))}
                  style={{ marginLeft: '5px', background: '#f0f0f0' }}
                >
                  <MercurXIcons
                    name="BiCopy"
                    size={10}
                    color={mainColors['primary']}
                  />
                </Button>
              </p>
              <img
                src={`data:image/png;base64,${Object.values(generateQrData).join('')}`}
                alt="QR Code"
                style={{ width: '200px', height: 'auto' }}
              />
            </div>
          )}

          {step === 2 && (
            <div>
              <Form.Group className="mb-3">
                <Form.Label>Enter 2FA code:</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="XXX-XXX"
                  value={inputValue}
                  onChange={handleInputChange}
                />
              </Form.Group>
            </div>
          )}
        </Modal.Body>

        <Modal.Footer>
          {step !== 1 && (
            <Button variant="primary" onClick={() => setStep((prevStep) => prevStep - 1)}>
              Back
            </Button>
          )}
          <Button
            variant="primary"
            onClick={() => (step !== 2 ? handleNextStep() : handleFinish())}
          >
            {step !== 2 ? 'Next' : 'Finish'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );

}

const mapStateToProps = (state) => {
  return {
    accountVerifiedData: state.userReducer.accountVerifiedData,
    user: state.userReducer.user,
    accounts: state.walletReducer.accounts,
    tierData: state.tierReducer.tierData,
    enable2faData: state.userReducer.enable2faData,
    enable2faError: state.userReducer.enable2faError,
    generateQrData: state.userReducer.generateQrData,
    generateQrError: state.userReducer.generateQrError,
    create2faData: state.userReducer.create2faData,
    create2faError: state.userReducer.create2faError,
    getKyc: state.userReducer.getKyc,
    kyc: state.userReducer.kyc,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    accountDetailsRequest: (payload) => {
      dispatch(accountDetailsRequestAction(payload));
    },
    resendVerificationEmailRequest: (payload) => {
      dispatch(resendVerificationEmailRequestAction(payload));
    },
    enable2faRequest: (payload) => {
      dispatch(enable2faRequestAction(payload));
    },
    generateQrRequest: (payload) => {
      dispatch(generateQrRequestAction(payload));
    },
    create2faRequest: (payload) => {
      dispatch(create2faRequestAction(payload));
    },
    logoutRequest: (payload) => {
      dispatch(logoutRequestAction(payload));
    },
    setAlert: (payload) => {
      dispatch(setAlertAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);