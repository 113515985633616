import * as types from './botActionTypes';

const initialState = {
  successStartStart: null,
  successUpdateStart: null,
  botData: null,
  botDataById: null,
  registeredBotData: null,
  filteredBotHistory: null,
  botHistoryData: null,
  botHistory: null,
  myBotHistoryData: null,
  botHistoryCountData: null,
  error: {
    type: null,
    data: null,
  },
  pnlHistoryData: null,
};

export const botReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_BOT_DATA:
      return {
        ...state,
        botData: action?.payload.result ? { ...action?.payload.result } : null,
      };
    case types.GET_BOT_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_BOT_ERROR,
          data: action.payload,
        },
      };
    case types.GET_BOT_BY_ID_DATA:
      return {
        ...state,
        botDataById: action?.payload.result ? { ...action?.payload.result } : null,
      };
    case types.GET_BOT_BY_ID_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_BOT_ERROR,
          data: action.payload,
        },
      };
    case types.GET_REGISTERED_BOT_DATA:
      console.log(action.payload) 
    return {        
        ...state,
        registeredBotData: action?.payload.result ? { ...action?.payload.result } : null,
      };
    case types.GET_REGISTERED_BOT_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_BOT_ERROR,
          data: action.payload,
        },
      };
    case types.REGISTER_DATA:
      return {
        ...state,
        successStart: action.successStart ? Object.assign({}, action.successStart) : null,
      };
    case types.REGISTER_ERROR:
      return {
        ...state,
        error: {
          type: types.REGISTER_ERROR,
          data: action.payload,
        },
      };
    case types.REGISTER_UPDATE_DATA:
      return {
        ...state,
        successUpdate: action.successUpdate ? Object.assign({}, action.successUpdate) : null,
      };
    case types.REGISTER_UPDATE_ERROR:
      return {
        ...state,
        error: {
          type: types.REGISTER_ERROR,
          data: action.payload,
        },
      };
    case types.GET_BOT_HISTORY_DATA:
      return {
        ...state,
        botHistoryData: action?.payload.result ? action?.payload?.result[0] : null,
        botHistoryCountData: action?.payload.result ? action?.payload?.result[1] : null,
      };
    case types.GET_CUSTOM_BOT_HISTORY_DATA:
      return {
        ...state,
        botHistoryData: action?.payload.result ? action?.payload?.result[0] : null,
        botHistoryCountData: action?.payload.result ? action?.payload?.result[1] : null,
      };
    case types.SET_BOT_HISTORY_SORT_DATA:
      return {
        ...state,
        botHistorySortData: { ...action.payload.result },
      };
    case types.GET_BOT_HISTORY_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_BOT_HISTORY_ERROR,
          data: action.payload,
        },
      };
    case types.GET_MY_BOT_HISTORY_DATA:
      return {
        ...state,
        myBotHistoryData: action?.payload.result ? action?.payload?.result[0] : null,
      };
    case types.SET_MY_BOT_HISTORY_SORT_DATA:
      return {
        ...state,
        myBotHistorySortData: { ...action.payload.result },
      };
    case types.GET_MY_BOT_HISTORY_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_MY_BOT_HISTORY_ERROR,
          data: action.payload,
        },
      };
    case types.GET_PNL_HISTORY_DATA:
      return {
        ...state,
        pnlHistoryData: action?.payload.result ? { ...action?.payload.result } : null,
      };
    case types.GET_PNL_HISTORY_ERROR:
      return {
        ...state,
        error: {
          type: types.GET_PNL_HISTORY_ERROR,
          data: action.payload.result,
        },
      };
    default:
      return state;
  }
};
