/* eslint-disable max-len */
import React, { useState, useEffect } from 'react';
import { Button, Row, Col, InputGroup, Form, Container, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { buyTokenModalAction } from '../../store/token/tokenActions';
import { useParams, useLocation } from 'react-router-dom';
import { setLoadingAction, setLoadingFullAction } from '../../store/loading/loadingActions';
import { registerRequest, registerUpdateRequest, unregisterRequest } from '../../store/bot/botActions';
import { getBotByIdRequestAction, getRegisteredBotRequestAction } from '../../store/bot/botActions';
import MercurXBackButton from '../../components/MercurXBackButton/MercurXBackButton';
import './BotManagementDetailInputs.scss';
function BotManagementDetailInputs({
    setLoadingFull,
    unregisterRequest,
    registerRequest,
    registerUpdateRequest,
    isEdit,
    user,
    getBotByIdRequest,
    registeredBotData,
    getRegisteredBotRequest,
    botDataById,
}) {
    const { id } = useParams();
    const location = useLocation();
    const [valueToBeMultiplied, setValueToBeMultiplied] = useState(0);
    const [valueToBeMultipliedParts, setValueToBeMultipliedParts] = useState(1);
    const [rangeValue, setRangeValue] = useState(0);
    const [showUnregisterPopup, setShowUnregisterPopup] = useState(false);
    const [exchangeName, setExchangeName] = useState('');
    const [showDisclaimerModal, setShowDisclaimerModal] = useState(false);
    const [disclaimerAccepted, setDisclaimerAccepted] = useState(false);
    const [modalDisclaimerAccepted, setModalDisclaimerAccepted] = useState(false);
    const bot_id = id;
    const item = location.state?.item;

    useEffect(() => {
        if (item) {
            const investment = parseFloat(item.investment) || 0;
            const portion = item.portion || 1;
            setValueToBeMultiplied(Math.round(investment));
            setValueToBeMultipliedParts(portion);
            setRangeValue(Math.round(investment));
        }
    }, [item]);

    useEffect(() => {
        const payload = { bot_id };
        getBotByIdRequest(payload);
    }, [bot_id, getBotByIdRequest]);

    function formatNumber(value) {
        if (typeof value !== 'number') {
            value = parseFloat(value);
        }
        return Math.round(value).toLocaleString('en-US').replace(/,/g, '.');
    }

    function parseNumber(value) {
        return parseFloat(value.replace(/\./g, '').replace(/,/g, '.')) || 0;
    }

    useEffect(() => {
        getRegisteredBotRequest();
    }, [getRegisteredBotRequest]);

    function handleValueChange(e) {
        const inputValue = e.target.value;
        const numericValue = parseNumber(inputValue);
        const maxBalance = parseNumber(calculateBalance());

        if (numericValue > maxBalance) {
            setValueToBeMultiplied(maxBalance);
            setRangeValue(maxBalance);
        } else {
            setValueToBeMultiplied(Math.round(numericValue));
            setRangeValue(Math.round(numericValue));
        }
    }

    function handleRangeChange(e) {
        const newValue = Math.round(parseFloat(e.target.value));
        setRangeValue(newValue);
        setValueToBeMultiplied(newValue);
    }

    function calculateBalance() {
        const selectedExchangeBalance = user?.[`${exchangeName}_balance`];
        return Number(selectedExchangeBalance || 0).toFixed(0);
    }


    function calculateParts() {
        return botDataById?.max_portion || 1;
    }

    const percentage = Math.min(((valueToBeMultiplied / parseNumber(calculateBalance())) * 100).toFixed(2), 100);
    const percentages = [25, 50, 75, 100];

    const setBudgetPercentage = (percentage) => {
        const budget = (parseNumber(calculateBalance()) * percentage) / 100;
        const roundedBudget = Math.round(budget);
        setValueToBeMultiplied(roundedBudget);
        setRangeValue(roundedBudget);
    };

    const startStrategie = async (e) => {
        e.preventDefault();
        if (!disclaimerAccepted) {
            setShowDisclaimerModal(true);
            return;
        }

        setLoadingFull({ isLoading: true, loadingText: 'Waiting...' });
        const usdt = valueToBeMultiplied;
        const portion = valueToBeMultipliedParts;
        const status = true;
        const exchange = exchangeName;
        try {
            const payload = { bot_id, usdt, portion, status, exchange };
            await registerRequest(payload);
            setLoadingFull({ isLoading: false });
        } catch (err) {
            setLoadingFull({ isLoading: false });
        }
    };

    const updateStrategie = async (e) => {
        e.preventDefault();
        setLoadingFull({ isLoading: true, loadingText: 'Waiting...' });
        const usdt = valueToBeMultiplied;
        const portion = valueToBeMultipliedParts;
        const status = true;
        const exchange = exchangeName;
        try {
            const payload = { bot_id, usdt, portion, status, exchange };
            await registerUpdateRequest(payload);
            setLoadingFull({ isLoading: false });
        } catch (err) {
            setLoadingFull({ isLoading: false });
        }
    };

    const unregisterBot = async () => {
        try {
            const payload = { bot_id };
            await unregisterRequest(payload);
        } catch (err) {
        } finally {
            setShowUnregisterPopup(false);
        }
    };

    const handleDisclaimerAccept = () => {
        setDisclaimerAccepted(true);
        setShowDisclaimerModal(false);
    };

    const handleDisclaimerCancel = () => {
        setDisclaimerAccepted(false);
        setModalDisclaimerAccepted(false);
        setShowDisclaimerModal(false);
    };
    useEffect(() => {
        setValueToBeMultiplied(0);
        setRangeValue(0);
    }, [exchangeName]);
    
    return (
        <Container>
            <MercurXBackButton buttonText="Back" />
            <div className="text-fs-head-sm text-start mb-3">Bot Management</div>
            <Row className="px-0 m-5">
                <Form.Select
                    className="date-range-select"
                    value={exchangeName}
                    onChange={(e) => setExchangeName(e.target.value)}
                    aria-label="Select exchange"
                >
                    <option value="" disabled>
                        Please select an exchange
                    </option>

                    {botDataById?.exchanges
                        ?.filter((exchange) => {
                            const balance = user?.[`${exchange.name}_balance`];
                            return balance && Number(balance) > 0;
                        })
                        .map((exchange, index) => (
                            <option key={index} value={exchange.name}>
                                {exchange.name.charAt(0).toUpperCase() + exchange.name.slice(1).toLowerCase()}
                            </option>
                        ))}
                </Form.Select>

                {(
                    <div className="shadow p-3 rounded">
                        <Row className="align-items-center mb-4">
                            <Col sm="12" md="6">
                                <div className="text-fs-head text-primary">Set the budget</div>
                            </Col>
                            <Col sm="12" md="6">
                                <InputGroup className="border-primary">
                                    <Form.Control
                                        className="border-primary border-end-0"
                                        step="any"
                                        type="text"
                                        value={formatNumber(valueToBeMultiplied)}
                                        onChange={handleValueChange}
                                        aria-describedby="merx-desc"
                                    />
                                    <InputGroup.Text className="border-primary bg-white text-muted" id="merx-desc">
                                        $
                                    </InputGroup.Text>
                                </InputGroup>
                            </Col>
                        </Row>
                        <Row className="mb-0">
                            <Form.Range
                                className="px-2"
                                step="any"
                                max={parseNumber(calculateBalance())}
                                min={0}
                                defaultValue={0}
                                value={rangeValue}
                                onChange={handleRangeChange}
                            />
                            <Row className="mb-0">
                                <div className="d-flex justify-content-end">
                                    <div>${formatNumber(parseNumber(calculateBalance()))}</div>
                                    <div className="ms-3">{percentage}%</div>
                                </div>
                            </Row>
                        </Row>
                        <Row className="m-0 justify-content-start">
                            {percentages.map((percentage, index) => (
                                <Col key={index} xs="auto">
                                    <Button size="sm" className="me-2" onClick={() => setBudgetPercentage(percentage)}>
                                        {percentage}%
                                    </Button>
                                </Col>
                            ))}
                        </Row>
                        <Row className="mt-5">
                            <div className="text-fs-head text-primary">How many parts do you want to divide the money into?</div>
                            <Form.Range
                                className="px-2"
                                step="any"
                                max={Number(calculateParts())}
                                min={1}
                                defaultValue={1}
                                value={valueToBeMultipliedParts}
                                onChange={(e) => setValueToBeMultipliedParts(parseInt(e.target.value))}
                            />
                            <div className="text-end">{valueToBeMultipliedParts}/{calculateParts()}</div>
                            {!isEdit ? <div className="d-flex align-items-center mt-3">
                                <Form.Check
                                    type="checkbox"
                                    label="I have read and understood the Multisignals API Disclaimer and Terms of Use."
                                    checked={modalDisclaimerAccepted}
                                    onChange={() => setShowDisclaimerModal(true)}
                                />
                            </div> : <></>}
                            {isEdit ? (
                                <Button
                                    className="mt-4"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        updateStrategie(e);
                                    }}
                                    disabled={
                                        valueToBeMultiplied > parseNumber(calculateBalance()) ||
                                        (!isEdit &&
                                            botDataById?.exchanges?.some(exchange =>
                                                exchange.name === registeredBotData?.bots?.[0]?.exchange &&
                                                botDataById?.id === registeredBotData?.bots?.[0]?.bot_id
                                            )
                                        )
                                    }
                                >
                                    Update
                                </Button>
                            ) : (
                                <Button
                                    className="mt-4"
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        Object.values(registeredBotData || {}).some(bot =>
                                            bot.registration_status === true
                                        ) ? updateStrategie(e) : startStrategie(e);
                                    }}
                                    disabled={
                                        valueToBeMultiplied > parseNumber(calculateBalance()) ||
                                        !disclaimerAccepted ||
                                        rangeValue <= 0 ||
                                        Object.values(registeredBotData || {}).some(bot =>
                                            bot.exchange === exchangeName && bot.registration_status === true
                                        )
                                    }
                                >
                                    {Object.values(registeredBotData || {}).some(bot =>
                                        bot.exchange === exchangeName && bot.registration_status === true
                                    )
                                        ? "Registered"
                                        : Object.values(registeredBotData || {}).some(bot =>
                                            bot.registration_status === true
                                        )
                                            ? "Update"
                                            : "Start"
                                    }
                                </Button>


                            )}

                            {isEdit && (
                                <Button
                                    className="mt-4"
                                    variant="danger"
                                    onClick={() => setShowUnregisterPopup(true)}
                                >
                                    Unregister
                                </Button>
                            )}
                        </Row>
                    </div>
                )}
            </Row>
            <Modal show={showDisclaimerModal} onHide={handleDisclaimerCancel} dialogClassName="custom-modal" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Multisignals API Disclaimer and Terms of Use</Modal.Title>
                </Modal.Header>
                <Modal.Body className="custom-modal-body">
                    <p className="text-fs-body-md">
                        By using the Multisignals API services, you acknowledge and agree to the following terms and conditions:
                    </p>
                    <ul>
                        <li>
                            <strong>1. API Key Usage and Responsibility: </strong>
                            Your API Key allows access to Multisignals services, including automated trading and data retrieval. You are fully responsible for securing your API Key and ensuring it is not accessed by unauthorized individuals or systems. Multisignals is not liable for any losses or unauthorized actions resulting from improper API Key management or third-party access.
                        </li>
                        <li>
                            <strong>2. Market Risks: </strong>
                            Cryptocurrency trading is inherently risky due to significant market volatility. Utilizing API services for automation does not eliminate risks or guarantee positive results. Multisignals will not be held responsible for any losses caused by market conditions, misconfigured API settings, or errors in your trading strategies.
                        </li>
                        <li>
                            <strong>3. Security and Permissions: </strong>
                            Ensure your API Key permissions are configured correctly (e.g., read-only, trading, or withdrawal). Grant only the access levels required for your intended operations. To enhance security, use IP whitelisting to restrict access to trusted sources only.
                        </li>
                        <li>
                            <strong>4. Service Availability and Limitations: </strong>
                            Multisignals provides API services "as-is" and does not guarantee uninterrupted access or the accuracy of data provided. Temporary service disruptions may occur due to system maintenance, technical updates, or external factors.
                        </li>
                        <li>
                            <strong>5. Compliance and Legal Responsibility: </strong>
                            By using Multisignals API services, you confirm that you comply with all relevant laws and regulations in your jurisdiction. Multisignals does not provide financial, tax, or legal advice. You are responsible for ensuring compliance with local regulations and obligations regarding cryptocurrency trading.
                        </li>
                        <li>
                            <strong>6. Termination of API Access: </strong>
                            Multisignals reserves the right to suspend or revoke API access at its discretion, particularly in cases of misuse or violations of these terms.
                        </li>
                        <li>
                            <strong>7. Acknowledgment of Risks: </strong>
                            Automated trading through APIs involves inherent risks, including strategy miscalculations, execution errors, or unexpected market conditions. You are solely accountable for the outcomes of your trading activities.
                        </li>
                    </ul>
                    <div className="d-flex justify-content-center mt-3">
                        <Form.Check
                            type="checkbox"
                            label="I acknowledge the risks associated with automated trading and agree to proceed at my own responsibility."
                            onChange={(e) => setModalDisclaimerAccepted(e.target.checked)}
                        />
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleDisclaimerAccept} disabled={!modalDisclaimerAccepted}>
                        Agree and Continue
                    </Button>
                </Modal.Footer>
            </Modal>
            <Modal
                show={showUnregisterPopup}
                onHide={() => setShowUnregisterPopup(false)}
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title>Confirm Deletion</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You are about to unregister from this bot. Please note that all your open trades will be canceled. Do you wish to proceed?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={() => setShowUnregisterPopup(false)}>
                        Cancel
                    </Button>
                    <Button
                        variant="danger"
                        onClick={unregisterBot}
                    >
                        Unregister
                    </Button>
                </Modal.Footer>
            </Modal>
        </Container>
    );
}

const mapStateToProps = (state) => ({
    user: state.userReducer.user,
    botDataById: state.botReducer.botDataById,
    registeredBotData: state.botReducer.registeredBotData,
});

const mapDispatchToProps = (dispatch) => ({
    buyTokenModalRequest: (payload) => dispatch(buyTokenModalAction(payload)),
    setLoading: (payload) => dispatch(setLoadingAction(payload)),
    setLoadingFull: (payload) => dispatch(setLoadingFullAction(payload)),
    registerRequest: (creds) => dispatch(registerRequest(creds)),
    registerUpdateRequest: (creds) => dispatch(registerUpdateRequest(creds)),
    unregisterRequest: (creds) => dispatch(unregisterRequest(creds)),
    getBotByIdRequest: (payload) => dispatch(getBotByIdRequestAction(payload)),
    getRegisteredBotRequest: () => dispatch(getRegisteredBotRequestAction()),
});

export default connect(mapStateToProps, mapDispatchToProps)(BotManagementDetailInputs);
