import './ApiSettings.scss';
import { Button, Container, Row, Col, Card, Modal } from 'react-bootstrap';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { buyTokenModalAction } from '../../store/token/tokenActions';
import AddNewApiModal from '../../components/AddNewApi/AddNewApiModal';
import AddNewApiOkxModal from '../../components/AddNewApiOkx/AddNewApiOkxModal';
import binanceMiniIcon from '../../assets/img/logo/binance.png';
import okxMiniIcon from '../../assets/img/logo/okxMiniLogo.png';
import { tierInfo } from '../../helpers/tierInfo';
import {
  accountDetailsRequestAction,
} from '../../store/account/userActions';
import {
  apiKeyDeleteRequest,
} from '../../store/apiKey/apiKeyActions';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function ApiSettings({ ...props }) {
  const {
    buyTokenModalRequest,
    tierData,
    accountDetailsRequest,
    user,
    apiKeyDeleteRequest,
    success,
    successDelete,
  } = props;

  const history = useHistory();

  const [stake, setStake] = useState(true);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showOkxModal, setShowOkxModal] = useState(false);
  const [selectedTierId, setSelectedTierId] = useState(null);
  const [selectedExchangeName, setSelectedExchangeName] = useState(null);

  const handleShowOkxModal = () => {
    setShowOkxModal(true);
  };
  const handleCloseOkxModal = () => {
    setShowOkxModal(false);
  };
  const handleSaveOkxApi = (apiDetails) => {
    console.log('OKX API Details:', apiDetails);
    setShowOkxModal(false);
  };
  const deleteApi = async () => {
    if (!selectedExchangeName) return;

    setIsLoading(true);
    try {
      const payload = { exchange: selectedExchangeName };
      await apiKeyDeleteRequest(payload);
    } catch (err) {
      console.error(err);
    } finally {
      setIsLoading(false);
      setShowDeletePopup(false);
    }
  };

  const stakeSetup = () => {
    setStake(true);
  };

  const handleShow = () => {
    buyTokenModalRequest(true);
  };

  const [selectedTier, setSelectedTier] = useState(tierInfo?.[tierData?.tier]);
  useEffect(() => {
    if (tierData?.tier !== null && tierData?.tier !== undefined) {
      setSelectedTier(tierInfo?.[tierData?.tier]);
    }
  }, [tierData]);

  useEffect(() => {
    if (props.success || props.successDelete !== null) {
      accountDetailsRequest();
    }
  }, [props.successDelete, props.success]);

  useEffect(() => {
    if (user?.api_key_status !== undefined) {
      accountDetailsRequest();
    }
  }, [user?.api_key_status, accountDetailsRequest]);

  const handleRedirectProfile = () => {
    history.push('/profile#profile');
  };

  return (
    <Container>
      <div className="text-fs-head-sm text-start">Api Settings</div>
      <Row className="mt-5">
        {tierInfo.map((item, index) => {
          const isOkx = index === 1;
          return (
            <Col key={index} sm={12} md={6} lg={6} className="tier-card">
              <Card className="border-0 py-2 px-3 shadow tier-card">
                <img
                  className="img-fluid mx-auto mb-2"
                  alt={isOkx ? 'okx-mini-icon' : 'binance-mini-icon'}
                  src={isOkx ? okxMiniIcon : binanceMiniIcon}
                  width={50}
                />
                <div className="text-fs-head-xs mx-auto mt-3">{item.title}</div>
                <div className="text-muted text-center mt-3 standart">
                  {item.standart}
                </div>
                {user ? (
                  item.id === 3 ? (
                    <Button className="token-button mt-5" disabled>
                      Coming Soon
                    </Button>
                  ) : user?.is_active !== 'True' ? (
                    <Button
                      className="token-button mt-5"
                      variant="primary"
                      onClick={handleRedirectProfile}
                    >
                      User Not Verified
                    </Button>
                  ) : (!(isOkx ? user?.okx || false : user?.binance) ? (
                    <Button
                      className="token-button mt-5"
                      variant="primary"
                      onClick={() => {
                        stakeSetup();
                        setSelectedTierId(item.id);
                        handleShow();
                      }}
                    >
                      Add New API
                    </Button>
                  ) : (
                    <Button
                      className="token-button mt-5"
                      variant="danger"
                      onClick={() => {
                        setSelectedExchangeName(item.title.toLowerCase());
                        setShowDeletePopup(true);
                      }}
                    >
                      Delete API
                    </Button>
                  ))
                ) : null}
              </Card>
            </Col>
          );
        })}
      </Row>

      <Modal
        show={showDeletePopup}
        onHide={() => setShowDeletePopup(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          You are about to delete this API Key. Please note that all open trades will be canceled,
          and your connected bot will be unregistered. To resume trading on the exchange,
          you will need to add your API Key again.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={() => setShowDeletePopup(false)}>
            Cancel
          </Button>
          <Button
            variant="danger"
            onClick={deleteApi}
            disabled={isLoading}
          >
            {isLoading ? 'Deleting...' : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>

      {selectedTierId === 1 && stake ? <AddNewApiModal /> : null}
      {selectedTierId === 2 ? <AddNewApiOkxModal /> : null}
    </Container>
  );
}

const mapStateToProps = (state) => ({
  tierData: state.tierReducer.tierData,
  user: state.userReducer.user,
  success: state.apiKeyReducer.success,
  successDelete: state.apiKeyReducer.successDelete,
});

const mapDispatchToProps = (dispatch) => ({
  buyTokenModalRequest: (payload) => {
    dispatch(buyTokenModalAction(payload));
  },
  accountDetailsRequest: (payload) => {
    dispatch(accountDetailsRequestAction(payload));
  },
  apiKeyDeleteRequest: (creds) => {
    dispatch(apiKeyDeleteRequest(creds));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiSettings);
