/* eslint-disable max-len */
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Form, Row, Col, Button, FormLabel, InputGroup, OverlayTrigger, Popover } from 'react-bootstrap';
import MercurXFormControl from '../../components/MercurXFormControl/MercurXFormControl';
import { signUpRequest } from '../../store/account/userActions';
import { NavLink } from 'react-router-dom';
import './SignUp.scss';
import MercurXIcons from '../../components/MercurXIcons/MercurXIcons';
import HCaptcha from '@hcaptcha/react-hcaptcha';
function SignUp({ ...props }) {
  const { sign, history } = props;
  const [captchaToken, setCaptchaToken] = useState(null);
  const [captchaError, setCaptchaError] = useState('');
  const [state, setState] = useState({
    data: {
      username: '',
      password: '',
      email: '',
    },
    errors: {},
    passwordVisibility: false,
  });

  const handleTogglePasswordVisibility = () => {
    setState(prevState => ({
      ...prevState,
      passwordVisibility: !prevState.passwordVisibility,
    }));
  };

  const validatePassword = (password) => {
    const errors = [];

    if (!password || password.length < 8) {
      errors.push({ message: 'Must be at least 8 characters long.', isValid: false });
    } else {
      errors.push({ message: 'Must be at least 8 characters long.', isValid: true });
    }

    if (!/[A-Z]/.test(password)) {
      errors.push({ message: 'Must contain at least one uppercase letter.', isValid: false });
    } else {
      errors.push({ message: 'Must contain at least one uppercase letter.', isValid: true });
    }

    if (!/[a-z]/.test(password)) {
      errors.push({ message: 'Must contain at least one lowercase letter.', isValid: false });
    } else {
      errors.push({ message: 'Must contain at least one lowercase letter.', isValid: true });
    }

    if (!/\d/.test(password)) {
      errors.push({ message: 'Must contain at least one digit.', isValid: false });
    } else {
      errors.push({ message: 'Must contain at least one digit.', isValid: true });
    }

    if (!/[@$!%*?&]/.test(password)) {
      errors.push({ message: 'Must contain at least one special character (@, $, !, %, *, ?, &).', isValid: false });
    } else {
      errors.push({ message: 'Must contain at least one special character (@, $, !, %, *, ?, &).', isValid: true });
    }

    return errors;
  };

  const validate = () => {
    const { data } = state;
    const errors = {};

    if (!data || typeof data !== 'object') {
      return errors;
    }

    if (!data.username) {
      errors.username = 'Username cannot be blank.';
    } else if (data.username.length < 3) {
      errors.username = 'Username must be at least 3 characters long.';
    }

    if (!data.email) {
      errors.email = 'Email cannot be blank.';
    } else {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(data.email)) {
        errors.email = 'Invalid email format.';
      }
    }

    if (!data.password) {
      errors.password = '*Password cannot be blank.';
    } else {
      const passwordErrors = validatePassword(data.password);
      const invalidPasswordErrors = passwordErrors.filter(error => !error.isValid);

      if (invalidPasswordErrors.length > 0) {
        errors.password = invalidPasswordErrors.map(error => error.message).join(' ');
      }
    }
    if (!captchaToken) {
      setCaptchaError('Please complete the captcha verification.');
    } else {
      setCaptchaError('');
    }
    return errors;
  };

  const passwordErrors = validatePassword(state.data.password);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { data } = state;

    const errors = validate();

    if (Object.keys(errors).length > 0 || !captchaToken) {
      setState(prevState => ({
        ...prevState,
        errors,
      }));
      return;
    }


    const payload = {
      data,
      history: history,
    };

    sign(payload);

    setState(prevState => ({
      ...prevState,
      errors: {},
      passwordVisibility: false,
    }));
  };


  const handleChange = (e) => {
    setState(prevState => ({
      ...prevState,
      data: {
        ...prevState.data,
        [e.target.id]: e.target.value,
      },
      errors: {
        ...prevState.errors,
        [e.target.id]: '',
      },
    }));
  };

  const { data, errors, passwordVisibility } = state;
  const popover = (
    <Popover id="popover-basic">
      <Popover.Body>
        {passwordErrors.map((error, index) => (
          <div key={index} className={`text-${error.isValid ? 'success' : 'danger'}`}>
            {error.isValid ? '✓' : '✗'} {error.message}
          </div>
        ))}
      </Popover.Body>
    </Popover>
  );
  const handleCaptchaVerification = (token) => {
    setCaptchaToken(token);
    setCaptchaError('');
  };
  return (
    <Row className="d-flex justify-content-center align-items-center">
      <Col className="public-signup-layout-col d-flex justify-content-center align-items-center bg-white px-1 py-2">
        <div className="public-signup-layout-image d-md-flex d-none flex-column justify-content-center align-items-center bg-primary px-1 py-2">
          <div className="text-fs-head-lg text-center text-light px-2">
            Welcome to the exciting world of <span>MultiSignals</span>
          </div>
          {/* <div className="text-fs-body-md text-center text-light px-2">
            Step into the dynamic sphere of MultiSignals, where each exploration promises fascinating
            discoveries in the world of digital currency and blockchain!
          </div> */}
        </div>
        <div className="d-flex flex-column justify-content-center aling-items-center px-4 mx-2 public-signup-layout-form">
          <Form onSubmit={handleSubmit}>
            <MercurXFormControl
              label="Your Name"
              type="text"
              id="username"
              value={data?.username}
              handleChange={handleChange}
              error={errors?.username}
              placeholder="Name Surname"
            />
            <Form.Label className={`text-${errors?.username ? 'danger' : 'success'} text-fs-body-sm mercurx-error-label`}>
              {errors?.username && '*' + errors?.username}
            </Form.Label>
            <MercurXFormControl
              label="Email"
              type="text"
              id="email"
              value={data?.email}
              handleChange={handleChange}
              error={errors?.email}
              placeholder="example@gmail.com"
            />
            <Form.Label className={`text-${errors?.email ? 'danger' : 'success'} text-fs-body-sm mercurx-error-label`}>
              {errors?.email && '*' + errors?.email}
            </Form.Label>
            <FormLabel className="text-fs-body-lg text-t-head-color">
              Password
            </FormLabel>
            <InputGroup>
              <OverlayTrigger style={{ marginLeft: '120px' }} trigger="focus" placement="right" overlay={popover}>
                <Form.Control
                  type={state.passwordVisibility ? 'text' : 'password'}
                  name="password"
                  id="password"
                  placeholder="••••"
                  value={state.data.password}
                  isInvalid={!!state.errors.password}
                  onChange={handleChange}
                />
              </OverlayTrigger>
              <InputGroup.Text id="basic-addon2" className="d-flex justify-content-between align-items-center input-group-text-custom">
                <Col>
                  <div onClick={handleTogglePasswordVisibility}>
                    <MercurXIcons
                      name={state.passwordVisibility ? 'BsEyeSlash' : 'BsEye'}
                      iconClassName="mercurx-password-icon"
                    />
                  </div>
                </Col>
              </InputGroup.Text>
              <Form.Control.Feedback type="invalid">{state.errors.password}</Form.Control.Feedback>
            </InputGroup>
            <Form.Group className="mt-4" controlId="termsAndConditions">
              <div className='mt-3 d-flex justify-content-center'>
                <HCaptcha
                  sitekey={process.env.REACT_APP_HCAPTCHA_SITE_KEY}
                  onVerify={handleCaptchaVerification}
                />
              </div>
              {captchaError && <Form.Label className="d-flex justify-content-center 
            text-danger text-fs-body-sm mercurx-error-label">*{captchaError}</Form.Label>}
            </Form.Group>

            <Button
              className="mb-4 mt-3 bg-mercurx-primary col-sm-12"
              type="submit"
              disabled={!captchaToken}>
              Sign up
            </Button>
          </Form>
          <Col className="m-1 d-flex justify-content-center">
            <NavLink
              className="text-fs-body-md text-t-body-color justify-content-center"
              to="/signup"
            >
              Do you have an account?
            </NavLink>

            <NavLink className="text-primary p-1" to="/login">
              Login
            </NavLink>
          </Col>
        </div>
      </Col>
    </Row>
  );
}

const mapDispatchToProps = (dispatch) => {
  return {
    sign: (creds) => {
      dispatch(signUpRequest(creds));
    },
  };
};

export default connect(null, mapDispatchToProps)(SignUp);