import { takeEvery, put, all, call,take } from 'redux-saga/effects';
import * as types from './userActionTypes';
import * as actions from './userActions';
import * as alert from '../alert/alertActions';
import * as endpoints from '../../services/endpoints';
import Swal from 'sweetalert2';

function* loginSaga({ creds }) {
  try {
    var formData = new FormData();
    formData.append('username', creds.data['username']);
    formData.append('password',  creds.data['password']);
    const { data } = yield call(endpoints.login, formData);
    yield put(actions.loginData(data));
    localStorage.setItem('user', JSON.stringify(data.result));
    if (data.result.two_factor_auth === true) {
      localStorage.setItem('temp_token', data.result.temp_token);
      
      yield call(startVerify2faSagaAction, data, creds);
    } else {
      yield call(processLoginWithout2FA, data, creds.history);
    }
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.message,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.loginError(e));
  }
}
function* processLoginWithout2FA(data, history) {
  yield put(actions.loginData(data));
  console.log(data)
  yield put(
    alert.setAlertAction({
      title: 'Success!',
      text: data.message,
      variant: 'success',
    }),    
  );
  const user = {
    user: data.result,
    token: data.result['token'],
  };
  localStorage.setItem('user', JSON.stringify(user));
  setTimeout(() => {
    history.push('/home');
  }, 1000);
}
function* startVerify2faSagaAction(loginData, loginPayload) {
  yield put(actions.loginData(loginData));
  console.log('data')
  console.log(loginData)
  yield put(alert.setAlertAction({ 
    title: 'Info', 
    text: loginData.message, 
    variant: 'info' 
  }));
  
  const verificationCodeAction = yield take('ENTER_VERIFICATION_CODE_ACTION');
  const verificationCode = verificationCodeAction.payload.code;

  yield call(verify2faSaga, {
    payload: {
      totp_code: verificationCode,
      loginPayload,
    },
  });
}

function* verify2faSaga({ payload }) {
  try {
    const {  totp_code, is_enabled, loginPayload,history, verifyToken } = payload;
    const { username, password } = loginPayload;
    const tempToken = localStorage.getItem('temp_token');
    const { data } = yield call(endpoints.verify2fa, totp_code );

    if (data) {
      localStorage.removeItem('temp_token');
      console.log('data')
      console.log(data)
      yield put(
        alert.setAlertAction({
          title: 'Success!',
          text: data.message,
          variant: 'success',
        }),        
      );

      
      yield put(actions.verify2faErrorAction(data));
      yield call(processLoginAfter2FA, loginPayload, data.result.token, loginPayload.history);
    } else {
      yield put(
        alert.setAlertAction({
          title: 'Error!',
          text: data.message  || 'Login failed.',
          variant: 'danger',
          outTimeMS: 6000,
        }),
      );
      yield put(actions.verify2faErrorAction(data.message));
    }
  } catch (e) {
   yield put(
     alert.setAlertAction({
       title: 'Error!',
       text: e?.response?.data?.message,
       variant: 'danger',
       outTimeMS: 6000,
     }),
   );
    yield put(actions.verify2faErrorAction(e));
  }
}
function* processLoginAfter2FA( loginPayload,accessToken, history) {
  var formData = new FormData();
  formData.append('username', loginPayload.username);
  formData.append('password', loginPayload.password);
  const { data } = yield call(endpoints.login, formData);
  yield put(actions.loginData(data));
  console.log('data')
  console.log(data)
  yield put(
    alert.setAlertAction({
      title: 'Success!',
      text: data.message,
      variant: 'success',
    }),    
  );
  const user = {
    user: loginPayload.result,
    token: accessToken,
  };
  localStorage.setItem('user', JSON.stringify(user));
  setTimeout(() => {
    window.location.href = '/home';
  }, 0)
}
function* forgotPasswordSaga({ creds }) {
  try {
    var formData = new FormData();
    formData.append('email', creds['email']);

    const { data } = yield call(endpoints.forgotPassword, formData);

    yield put(actions.forgotPasswordData(data));

    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text : data.message,
        variant: 'success',
        outTimeMS: 8000,
      }),
    );
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.message,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.forgotPasswordError(e));
  }
}
function* resetPasswordSaga({ creds }) {
  try {
    var formData3 = new FormData();

    formData3.append('password', creds['password']);
    formData3.append('confirmPassword', creds['confirmPassword']);
    formData3.append('resetToken', creds['resetToken']);
    const body = { token: creds['resetToken'], new_password: creds['password'] };

    const { data } = yield call(endpoints.resetPassword, body);
    yield put(actions.resetPasswordData(data));

    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: data.message,
        variant: 'success',
      }),
    );
    setTimeout(() => {
      creds.history.push('/login');
    }, 2000);
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.message,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.resetPasswordError(e));
  }
}
function* activationSaga({ creds }) {
  try {
    var formData3 = new FormData();

    formData3.append('activationCode', creds['activationCode']);
    formData3.append('activationToken', creds['activationToken']);
    const body = { token: creds['activationToken'], activation_code: creds['activationCode'] };
    const { data } = yield call(endpoints.activation, body);
    yield put(actions.activationData(data));

    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: data.message,
        variant: 'success',
      }),
    );
    yield put(actions.accountVerifiedAction(true));
    setTimeout(() => {
      creds.history.push('/home');
    }, 3000);
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.message,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.activationError(e));
  }
}

export function* logoutSaga() {
  localStorage.removeItem('user');
  const checkUser = localStorage.getItem('user');
  yield put(actions.logoutData());
}

function* signUpSaga({ creds }) {
  try {
    var formData = new FormData();
    formData.append('username', creds.data['username']);
    formData.append('password', creds.data['password']);
    formData.append('email', creds.data['email']);

    const { data } = yield call(endpoints.signUp, formData);
    yield put(actions.signUpData(data));

    yield put(
      alert.setAlertAction({
        title: 'Success!',
        text: data.message,
        variant: 'success',
      }),
    );
    creds.history.push('/login');
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.message,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.signUpError(e));
  }
}

function* getAccountDetailsSaga({ creds }) {
  try {
    const { data } = yield call(endpoints.getAccountDetails);
    yield put(actions.accountDetailsDataAction(data));
  } catch (e) {
    yield put(actions.accountDetailsErrorAction(e));
  }
}

function* resendVerificationEmail({ payload }) {
  try {
    const { data } = yield call(endpoints.resendVerificationEmail);
    yield put(actions.resendVerificationEmailDataAction(data));

    Swal.fire({
      icon: 'success',
      title: 'Success!',
      text: data.message,
    });
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.message,
        variant: 'danger',
        outTimeMS: 6000,
      }),
    );
    yield put(actions.resendVerificationEmailErrorAction(e));
  }
}

function* checkUserToken({ payload }) {
  try {
    const { data } = yield call(endpoints.checkUserToken);
    yield put(actions.checkUserTokenDataAction(data));
  } catch (e) {
    if (e) {
      yield call(logoutSaga);
      yield put(
        alert.setAlertAction({
          title: 'Session Expired!',
          text: e?.response?.data?.message,
          variant: 'danger',
          outTimeMS: 6000,
        }),
      );
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    }
    yield put(actions.checkUserTokenErrorAction(e));
  }
}

function* checkUserTokenWeb3({ payload }) {
  try {
    const { data } = yield call(endpoints.checkUserToken);
    yield put(actions.checkUserTokenDataAction(data));

  } catch (e) {
    if (e) {
      yield call(logoutSaga);
      yield put(
        alert.setAlertAction({
          title: 'Session Expired!',
          text: e?.response?.data?.message,
          variant: 'danger',
          outTimeMS: 0,
        }),
      );
      setTimeout(() => {
        window.location.reload();
      },);
    }
    yield put(actions.checkUserTokenErrorAction(e));
  }
}

function* enable2faSaga({ payload  }) {
  try {
    const {  totp_code } = payload;
    const { data } = yield call(endpoints.enable2fa, totp_code);
    if (data) {
      yield put(actions.enable2faDataAction(data));
      yield put(
        alert.setAlertAction({
          title: 'Success!',
          text: data.message,
          variant: 'success',
        })
      );
    } else {
      yield put(
        alert.setAlertAction({
          title: 'Error!',
          text: data.error,
          variant: 'danger',
          outTimeMS: 6000,
        })
      );
      yield put(actions.enable2faErrorAction(data.error));

    }
  } catch (e) {
    yield put(
      alert.setAlertAction({
        title: 'Error!',
        text: e?.response?.data?.message,
        variant: 'danger',
        outTimeMS: 6000,
      })
    );
    yield put(actions.enable2faErrorAction(e));
  }
}

function* generateQrSaga({ payload }) {
  try {
    const { userId } = payload;
    const { data } = yield call(endpoints.getGenerateQR, userId);
    yield put(actions.generateQrDataAction(data));
  } catch (e) {
    yield put(actions.generateQrErrorAction(e));
  }
}
function* create2faSaga({ payload }) {
  try {
    const { userId } = payload ;
    const { data } = yield call(endpoints.create2fa, userId);
    yield put(actions.create2faErrorAction(data));
  } catch (e) {
    yield put(actions.create2faErrorAction(e));
  }
}


function* watchVerify2fa() {
  yield takeEvery(types.VERIFY_2FA_REQUEST, verify2faSaga);
}

function* watchCreate2fa() {
  yield takeEvery(types.CREATE_2FA_REQUEST, create2faSaga);
}
function* watchEnable2fa() {
  yield takeEvery(types.ENABLE_2FA_REQUEST, enable2faSaga);
}
function* watchGenerateQr() {
  yield takeEvery(types.GENERATE_QR_REQUEST, generateQrSaga);
}

function* watchLoginUser() {
  yield takeEvery(types.LOGIN_REQUEST, loginSaga);
}

function* watchLogoutUser() {
  yield takeEvery(types.LOGOUT_REQUEST, logoutSaga);
}

function* watchSignUpUser() {
  yield takeEvery(types.SIGN_UP_REQUEST, signUpSaga);
}
function* watchForgotPassword() {
  yield takeEvery(types.FORGOT_PASSWORD_REQUEST, forgotPasswordSaga);
}
function* watchResetPassword() {
  yield takeEvery(types.RESET_PASSWORD_REQUEST, resetPasswordSaga);
}
function* watchActivation() {
  yield takeEvery(types.ACTIVATION_REQUEST, activationSaga);
}
function* watchAccountDetails() {
  yield takeEvery(types.ACCOUNT_DETAILS_REQUEST, getAccountDetailsSaga);
}
function* watchResendVerificationEmail() {
  yield takeEvery(types.RESEND_VERIFICATION_EMAIL_REQUEST, resendVerificationEmail);
}
function* watchCheckUserToken() {
  yield takeEvery(types.CHECK_USER_TOKEN_REQUEST, checkUserToken);
  
}
function* watchCheckUserTokenWeb3() {
  yield takeEvery(types.CHECK_USER_TOKEN_REQUEST, checkUserTokenWeb3);
}
export function* userSaga() {
  yield all([
    watchLoginUser(),
    watchLogoutUser(),
    watchSignUpUser(),
    watchForgotPassword(),
    watchResetPassword(),
    watchActivation(),
    watchAccountDetails(),
    watchResendVerificationEmail(),
    watchCheckUserToken(),
    watchEnable2fa(),
    watchGenerateQr(),
    watchVerify2fa(),
    watchCreate2fa(),
    watchCheckUserTokenWeb3()
  ]);
}
