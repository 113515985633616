import { useEffect, useState } from 'react';
import { Pagination, Table, Badge } from 'react-bootstrap';
import { connect } from 'react-redux';
// import { Link } from 'react-router-dom';
import './BotRegisteredManagementTable.scss';
import MercurXIcons from '../MercurXIcons/MercurXIcons';
import { mainColors } from '../../helpers/colors';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

function BotRegisteredManagementTable(props) {
  const {
    registeredBotData,
  } = props;
  const [txs, setTxs] = useState([]);

  const history = useHistory();
  const maxRowCountPerPage = 5;
  const maxShowingPage = 5;
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [renderedData, setRenderedData] = useState([]);
  const [renderedPages, setRenderedPages] = useState([]);
  const [lastPageOfPagination, setLastPageOfPagination] = useState(maxShowingPage);
  useEffect(() => {
    if (registeredBotData) {
      setCurrentPage(1);
      const tempPages = [];
      for (
        let index = 0;
        index < Math.ceil(Object.values(registeredBotData || {}).length / maxRowCountPerPage);
        index++
      ) {
        tempPages.push(index + 1);
      }
      setPages([...tempPages]);
      setRenderedPages([
        ...tempPages.slice(lastPageOfPagination - maxShowingPage, lastPageOfPagination),
      ]);
    }
  }, [registeredBotData]);

  useEffect(() => {
    if (registeredBotData && currentPage) {
      const firstIndex = maxRowCountPerPage * currentPage - maxRowCountPerPage;
      const lastIndex = maxRowCountPerPage * currentPage;
      const tempRendered = Object.values(registeredBotData || {}).slice(firstIndex, lastIndex);
      setRenderedData([...tempRendered]);
    }
  }, [currentPage, registeredBotData]);

  function scrollToBotHistoryContainer() {
    const botHistory = document.getElementById('bot-history-container');
    if (botHistory) {
      botHistory.scrollIntoView({ behavior: 'smooth' });
    }
  }

  const setPaginationPages = (page) => {
    if (
      renderedPages.findIndex((p) => p === page + 1) === -1 &&
      pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination + 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination + 1 - maxShowingPage, lastPageOfPagination + 1),
      );
    } else if (
      renderedPages.findIndex((p) => p === page - 1) === -1 &&
      pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1).length ===
      maxShowingPage
    ) {
      setLastPageOfPagination(lastPageOfPagination - 1);
      setRenderedPages(
        pages.slice(lastPageOfPagination - 1 - maxShowingPage, lastPageOfPagination - 1),
      );
    }
  };

  const changePage = (page) => {
    setCurrentPage(page);
    if (page === lastPageOfPagination) {
      setPaginationPages(page);
    } else if (page === lastPageOfPagination - maxShowingPage + 1) {
      setPaginationPages(page);
    }
    scrollToBotHistoryContainer();
  };

  const firstPage = () => {
    setCurrentPage(1);
    setLastPageOfPagination(maxShowingPage);
    setRenderedPages(pages.slice(0, maxShowingPage));
    scrollToBotHistoryContainer();
  };

  const prevPage = () => {
    if (currentPage - 1 !== 0) {
      setCurrentPage(currentPage - 1);
      setPaginationPages(currentPage - 1);
      scrollToBotHistoryContainer();
    }
  };

  const nextPage = () => {
    if (currentPage + 1 <= pages.length) {
      setCurrentPage(currentPage + 1);
      setPaginationPages(currentPage + 1);
      scrollToBotHistoryContainer();
    }
  };

  const lastPage = () => {
    setCurrentPage(pages.length);
    if (pages.length > maxShowingPage) {
      setLastPageOfPagination(pages.length);
      setRenderedPages(pages.slice(pages.length - maxShowingPage, pages.length));
    }
    scrollToBotHistoryContainer();
  };
  function editBot(item) {
    history.push({
      pathname: `/general-settings/update-bot-management/${item.bot_id}`,
      state: {
        item: item,
      },
    });
  }
  const formatValue = (value) => {
    return value.toLocaleString('tr-TR');
  };
  return (
    <>
      <Table className="registered-bot-table" responsive hover borderless>
        <thead>
          <tr className="text-t-body-color text-fs-tag">
            <th>#</th>
            <th>Exchange</th>
            <th>Bot Name</th>
            <th>Investment</th>
            <th>Portion</th>
            <th>Status</th>
          </tr>
        </thead>
        {renderedData?.length ? (
          <tbody>
            {renderedData.map((item, index) => {
              if (currentPage * index < currentPage * maxRowCountPerPage) {
                return (
                  <tr key={index} className="text-t-head-color">
                    <td>{index + 1}</td>
                    <td>{item.exchange.charAt(0).toUpperCase() +
                      item.exchange.slice(1).toLowerCase()}</td>
                    <td>{item.bot_name}</td>
                    <td>{'$' + formatValue(item.investment)}</td>
                    <td>{item.portion}</td>
                    <td>
                      {item.registration_status ? (
                        <Badge bg="success">Active</Badge>
                      ) : (
                        <Badge bg="danger">Passive</Badge>
                      )}
                    </td>
                    <td>
                      <div className="cursor-pointer mt-1" onClick={() => editBot(item)}>
                        <MercurXIcons
                          name="BsPencilSquare"
                          size={18}
                          color={mainColors['primary']}
                        />
                      </div>
                    </td>
                  </tr>
                );
              }
            })}
          </tbody>
        ) : null}
      </Table>

      {!renderedData?.length && (
        <div className="d-flex text-fs-body-md text-t-body-color justify-content-center">
          No Rendered Bot found.
        </div>
      )}

      {pages?.length > 1 ? (
        <Pagination className="d-flex justify-content-center">
          <Pagination.First onClick={() => firstPage()} />
          <Pagination.Prev onClick={() => prevPage()} />
          {renderedPages.map((page, index) => {
            return (
              <Pagination.Item
                key={index}
                active={page === currentPage}
                onClick={() => changePage(page)}
              >
                {page}
              </Pagination.Item>
            );
          })}
          <Pagination.Next onClick={() => nextPage()} />
          <Pagination.Last onClick={() => lastPage()} />
        </Pagination>
      ) : null}
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    registeredBotData: state.botReducer.registeredBotData,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(BotRegisteredManagementTable);
