import React, { useEffect } from 'react';
import './MyBotHistory.scss';
import { Container, Col, Card, Row, Button } from 'react-bootstrap';
import { connect } from 'react-redux';
import {
  getBotRequestAction,
  getRegisteredBotRequestAction,
  registerRequest
} from '../../store/bot/botActions';
import { setLoadingAction, setLoadingFullAction } from '../../store/loading/loadingActions';
import { useHistory, useLocation } from 'react-router-dom';
import {
  accountDetailsRequestAction
} from '../../store/account/userActions';
import bnbusdt from '../../assets/img/coin/bnbusdt.png';
import okxusdt from '../../assets/img/coin/okxusdt.png';

function MyBotHistory({ ...props }) {
  const {
    botData,
    registeredBotData,
    getBotRequest,
    getRegisteredBotRequest,
    setLoadingFull,
    registerRequest,
    user,
    accountDetailsRequest,
  } = props;

  const history = useHistory();
  const location = useLocation();
  const { exchanges } = location.state || {};
  useEffect(() => {
    getBotRequest();
  }, [getBotRequest]);

  useEffect(() => {
    accountDetailsRequest();
  }, []);

  useEffect(() => {
    getRegisteredBotRequest();
  }, [getRegisteredBotRequest]);

  const botDataArray = registeredBotData && typeof registeredBotData === "object"
  ? Object.values(registeredBotData)
      .map(bot => {
        if (botData?.[bot.bot_id - 1]) {
          return {
            ...botData[bot.bot_id - 1],
            start_date: bot.start_date,
          };
        }
        return null;
      })
      .filter(item => item !== null)
  : [];


  const routeStrategie = async (e, id) => {
    history.push(`/general-settings/bot-management/${id}`);
  };

  const redirectApiKey = async () => {
    history.push('/general-settings/api-settings');
  };

  const handleCardClick = (id, exchanges) => {
    history.push({
      pathname: `/bot-detail/${id}`,
      state: { exchanges }
    });
  };

  const redirectToMarketplace = () => {
    history.push('/marketplace');
  };

  return (
    <Container>
  <div className="text-fs-head-sm text-start">
    {botDataArray.length < 2 ? "My Registered Bot" : "My Registered Bots"}
  </div>
  <div className="border-0 mt-4 position-relative">
    {botDataArray.length > 0 ? (
      <Row className="mb-5">
        {botDataArray.map((item, index) => (
          <Col key={index} sm={12} md={6} lg={4}>
            <Card
              className="mybothistory-card py-2 px-3 shadow cursor-pointer"
              onClick={() => handleCardClick(item.id, item.exchanges)}
            >
              <div className="text-fs-head-xs mx-auto">{item.name}</div>
              <div className="d-flex justify-content-center align-items-center">
                {item.status ? "Active" : "Inactive"}
              </div>
              <hr />
              <div className="card-content">
              <div className="text-fs-body-md">
                      • {item.exchanges.length > 1 ? 'Exchanges' : 'Exchange'}:
                      <span> </span>
                      {item.exchanges.map((exchange, idx) => (
                        <span key={idx}>
                          <span>
                            {exchange.name.charAt(0).toUpperCase() +
                             exchange.name.slice(1).toLowerCase()}
                          </span>
                          <img
                            src={exchange.name.toLowerCase() === 'binance' ? bnbusdt : okxusdt}
                            width={24}
                            height={24}
                            style={{ marginLeft: 2, marginBottom: 3 }}
                          />
                          {idx !== item.exchanges.length - 1 && <span>&nbsp;</span>}
                        </span>
                      ))}
                    </div>
                <div className="text-fs-body-md">
                  • Creator: {item.creator}
                </div>
                <div className="text-fs-body-md">
                  • Bot Created Date: {new Date(item.cdate).toLocaleString()}
                </div>
                <div className="text-fs-body-md">
                  • Started Date: {new Date(item.start_date).toLocaleString()}
                </div>
                <div className="text-fs-body-md">• PNL: %{item.pnl}</div>
              </div>
              <hr />
            </Card>
          </Col>
        ))}
      </Row>
    ) : (
      <div className="text-center">
        <p>
          Currently, you have no registered bots. To register a bot, visit the
          marketplace.
        </p>
        <Button variant="primary" onClick={redirectToMarketplace}>
          Go to Marketplace
        </Button>
      </div>
    )}
  </div>
</Container>

  );
}

const mapStateToProps = (state) => {
  return {
    botData: state.botReducer.botData,
    registeredBotData: state.botReducer.registeredBotData,
    user: state.userReducer.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBotRequest: (payload) => {
      dispatch(getBotRequestAction(payload));
    },
    getRegisteredBotRequest: (payload) => {
      dispatch(getRegisteredBotRequestAction(payload));
    },
    setLoading: (payload) => {
      dispatch(setLoadingAction(payload));
    },
    setLoadingFull: (payload) => {
      dispatch(setLoadingFullAction(payload));
    },
    registerRequest: (creds) => {
      dispatch(registerRequest(creds));
    },
    accountDetailsRequest: (payload) => {
      dispatch(accountDetailsRequestAction(payload));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyBotHistory);
