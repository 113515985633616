import * as types from './apiKeyActionTypes';

const initialState = {
  success: null,
  successDelete: null,
  error: {
    type: null,
    data: null,
  },
};

export const apiKeyReducer = (state = initialState, action) => {
  switch (action.type) {
      case types.APIKEY_DATA:
        console.log('APIKEY_DATA.payload')
        console.log(action.payload)
      return {
        ...state,
        success: {
          Message: action.payload.Message,
          Result: action.payload.Result
        }
      };
      
    case types.APIKEY_ERROR:
      console.log('APIKEY_DATA.payload')
      console.log(action.payload)
      return {
        ...state,
        error: {
          type: types.APIKEY_ERROR,
          data: action.payload,
        },
      };
      case types.APIKEY_DELETE_DATA:
        console.log('APIKEY_DATA.payload')
        console.log(action.payload)
      return {
        ...state,
        successDelete: {
          Message: action.payload.Message,
          Result: action.payload.Result
        }
      };
      
    case types.APIKEY_DELETE_ERROR:
      console.log('APIKEY_DATA.payload')
      console.log(action.payload)
      return {
        ...state,
        error: {
          type: types.APIKEY_DELETE_ERROR,
          data: action.payload,
        },
      };
    default:
      return state;
  }
};
