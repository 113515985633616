/* eslint-disable max-len */
export const tierInfo = [
  {
    id: 1,
    title: 'Binance',
    standart: 'You can complete your API settings for Multisignals to communicate with your stock account in just a few minutes!',
    
  },
  {
    id: 2,
    title: 'OKX',
    standart: 'You can complete your API settings for Multisignals to communicate with your stock account in just a few minutes!',
  },
   {
    id: 3,
    title: 'Binance TR',
    standart: 'You can complete your API settings for Multisignals to communicate with your stock account in just a few minutes!',
  },
  // {
  //   id: 3,
  //   title: 'TIER 2',
  //   multiplier: 1.5,
  //   months: 6,
  //   features: [
  //     {
  //       content: 'Between 1000 - 4000 MERX',
  //       highlight: true,
  //     },
  //     {
  //       content: 'Silver User.',
  //       highlight: false,
  //     },
  //     {
  //       content: 'Early Access to Special Events',
  //       highlight: false,
  //     },
  //   ],
  // },
  // {
  //   id: 4,
  //   title: 'TIER 3',
  //   multiplier: 2,
  //   months: 12,
  //   features: [
  //     {
  //       content: '> 4000 MERX',
  //       highlight: true,
  //     },
  //     {
  //       content: 'Gold User.',
  //       highlight: false,
  //     },
  //     {
  //       content: 'Guarantee buying option for Special Event',
  //       highlight: false,
  //     },
  //   ],
  // },
];
